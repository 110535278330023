<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h6 font-weight-regular">
        Inserimento Sigilli {{ tipoSigilloCustom }}
      </v-col>
    </v-row>
    <v-expansion-panels v-model="panel" :disabled="disabled" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="text-body-1 text-uppercase font-weight-regular py-2 panelheader"
        >
          Gestione Sigilli
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              <v-text-field label="Sigillo" v-model="sigilloNew" />
            </v-col>

            <v-col cols="4">
              <v-text-field label="Tipo Sigillo" v-model="tipoSigilloNew" disabled/>
            </v-col>

            <v-col cols="4">
          
            </v-col>
            <v-col cols="2">
              <v-btn @click="associaSigillo()" :disabled= "sigilloNew == null" x-large color="primary">
                <v-icon x-large>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :items="sigilliAssociati"
            :headers="assignHeaders"
            class="text-h6 text-uppercase font-weight-regular"
          >
            <template v-slot:item.action="{ item }">
              <v-btn icon title="Cancella" @click="cancellaSigillo(item)">
                <v-icon color="primary" medium x-large>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import ToastMixin from "../../mixins/ToastMixin";
import DataTableMixin from "../../mixins/DataTableMixin";
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";

export default {
  name: "ConvoyAssociaSigilli",
  tipoUti: null,
  components: {},
  mixins: [ToastMixin],
  props: ["carroConvoglioId", "tipoSigillo", "visitaUtiId", "codiceUti"],
  components: {
    TosListVuetify,
  },
  data() {
    return {
      carroConvoglio: {},
      sigilliAssociati: [],
      sigilloNew: null,
      tipoSigilloNew: null,
      panel: [0],
      disabled: false,
      listTipiSigillo: [],
      tipoSigilloSelected: null,
    };
  },
  created() {},
  async mounted() {
    await this.$api.get(
      this.$apiConfiguration.BASE_PATH +
        "tipiSigillo/list" 
    ).then(result => {
        this.listTipiSigillo = result.data;
        this.listTipiSigillo.forEach(element => {
            if (element.codSigillo === this.tipoSigillo){
                this.tipoSigilloNew = element.codSigillo;
                this.tipoSigilloSelected = element.id;
            }
        });

    })

    this.carroConvoglio = await this.$api.get(
        this.$apiConfiguration.BASE_PATH +
          "carriConvoglio/" +
          this.carroConvoglioId
      );

    if (this.tipoSigillo === "CARRO") {
      this.sigilliAssociati = await this.$api.get(
        this.$apiConfiguration.BASE_PATH +
          "sigillo/carroConvoglio/" +
          this.carroConvoglioId
      );;
    }

    if (this.tipoSigillo === "UTI") {
      this.sigilliAssociati = await this.$api.get(
        this.$apiConfiguration.BASE_PATH + "sigillo/listByVisitaUtiId/" + this.visitaUtiId
      );;
    }
  },
  computed: {
    targaCarro() {
      if (this.carroConvoglio && this.carroConvoglio.visitaCarro) {
        return this.carroConvoglio.visitaCarro.targa;
      }
    },
    tipoSigilloCustom() {
      if (this.tipoSigillo === "CARRO") {
        return "Carro " + this.targaCarro;
      }

      if (this.tipoSigillo === "UTI") {
        return "Uti " + this.codiceUti;
      }
    },
    assignHeaders() {
      if (this.tipoSigillo === "CARRO") {
        return [
          { text: "", value: "action" },
          { text: "Sigillo Carro", value: "carroSigillo" },
          { text: "Tipo Sigillo", value: "codSigillo" },
          { text: "Desc Tipo Sigillo", value: "descCodSigillo" },

        ];
      }

      if (this.tipoSigillo === "UTI") {
        return [
          { text: "", value: "action" },
          { text: "Sigillo Uti", value: "utiSigillo" },
          { text: "Tipo Sigillo", value: "codSigillo" },
          { text: "Desc Tipo Sigillo", value: "descCodSigillo" },
        ];
      }
    },
  },
  methods: {
    async associaSigillo() {
      try{
      if (this.tipoSigillo === "CARRO") {
        const createSigilloCarroFullPath =
          this.$apiConfiguration.BASE_PATH + "sigillo/createByCarroConvoglio";
        const reqCreateSigilloObj = {
          carroConvoglioId: this.carroConvoglio.id,
          sigilloCarro: this.sigilloNew,
          tipoSigilloId: this.tipoSigilloSelected,
        };
        await this.$api.post(createSigilloCarroFullPath, reqCreateSigilloObj);

        this.sigilliAssociati = await this.$api.get(
          this.$apiConfiguration.BASE_PATH +
            "sigillo/carroConvoglio/" +
            this.carroConvoglio.id
        );
      }

      if (this.tipoSigillo === "UTI") {
        const createSigilloUtiFullPath =
          this.$apiConfiguration.BASE_PATH + "sigillo/createByVisitaUti";
        const reqCreateSigilloUtiObj = {
          visitaUtiId: this.visitaUtiId,
          carroConvoglioId: this.carroConvoglio.id,
          sigilloUti: this.sigilloNew,
          tipoSigilloId: this.tipoSigilloSelected,
        };
        await this.$api.post(createSigilloUtiFullPath, reqCreateSigilloUtiObj);

        this.sigilliAssociati = await this.$api.get(
          this.$apiConfiguration.BASE_PATH + "sigillo/listByVisitaUtiId/" + this.visitaUtiId
        );;
      }
      this.sigilloNew = null;
      } catch(e){
        console.log("associaSigillo", e);
        this.showError('Si è verificato un errore '+e.message);
      }
    },

    async associaSigilloUti(utiCarro) {
      const createSigilloUtiFullPath =
        this.$apiConfiguration.BASE_PATH + "sigillo/createByUtiCarro";
      const reqCreateSigilloObj = {
        utiCarroId: uticarro.id,
        carroConvoglioId: carroConvoglio.id,
        sigilloUti: this.sigilloNew,
        tipoSigilloId: this.tipoSigilloSelected,
      };
      await this.$api.post(createSigilloUtiFullPath, reqCreateSigilloObj);

      this.sigilliAssociati = await this.$api.get(
        this.$apiConfiguration.BASE_PATH +
          "sigillo/carroConvoglio/" +
          carroConvoglio.id
      );
      this.sigilloNew = null;
    },

    async cancellaSigillo(item) {
    await this.$api.delete(
        this.$apiConfiguration.BASE_PATH +
          "sigillo/" +
          item.id
      );
      this.sigilliAssociati.splice( this.sigilliAssociati.indexOf(item),1);
    },
  },
};
</script>

<style scoped>
.uti-rob {
  background-image: repeating-linear-gradient(
    -45deg,
    #ffffff,
    #ffffff 5px,
    rgb(196, 196, 192) 5px,
    rgb(196, 196, 192) 10px
  ) !important;
}
</style>
